import React, { FC, PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../atoms/Button";
import styles from "./Auth.module.scss";
import { ButtonSize } from "../../atoms/Button/Button.types";
import { useRouter } from "next/router";
import { useAuthentication } from "../../../auth/AuthenticationContext";

const LoginPage: FC<PropsWithChildren> = ({ children, ...props }) => {
  const { status, signIn } = useAuthentication();
  const router = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    if (status === "authenticated") {
      router.push("/");
    }
  }, [status, router]);

  if (status === "initializing" || status === "authenticated") {
    return null;
  }

  return (
    <div className={styles.authPage}>
      <img src="/marcanti-logo.png" alt="logo" className={styles.authLogo} />
      <p className={styles.authFooter}>{t("login_page.footer")}</p>
      <div className={styles.contentSide}>
        <div className={styles.auth}>
          <h1>{t("login_page.title")}</h1>
          <p className={styles.authText}>{t("login_page.text")}</p>
          <Button data-testid="login-button" size={ButtonSize.LARGE} onClick={signIn}>
            {t("login")}
          </Button>
        </div>
      </div>
      <div className={styles.imageSide}></div>
    </div>
  );
};

export default LoginPage;
